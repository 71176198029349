/* Gradient background for the entire page with animation */
body {
    background: linear-gradient(45deg, #f3f4f7, #f0f1f4);
    background-size: 400% 400%;
    animation: gradientMove 15s ease infinite;
    font-family: 'Arial', sans-serif;
}
.Bimage{
    height: 50vh;
}
.carousel-inner{
    height: 65vh;
}
.A{
    height: 30vw !important;
    object-fit: cover;
}
.Ab{
   height: 50vh;
   object-fit: cover;
}
.Abb{
    height: 17vw;
    object-fit: cover;
}
@media (max-width: 768px) {
    .A {
        height: 85vw !important;
    }
    .Ab {
        height: 30vh;
    }
    .Abb {
        height: 50vw;
    }
    
    .carousel-inner{
        height: 40vh;
    }
    .about_link{
        margin-top: 2px !important;
    }
    .pro{
        margin-left: 10vh !important;
    }
    .H{
        font-size: 2rem !important;
        justify-content: center;
    }
    .info_text{
      height: 45vh !important;
    }
   
}
.H{
  color:white;
  font-size: 3rem;
  font-weight: bold;
  
}
.p{
  color:white;
}
.b{
  color:white !important;
  background-color: brown !important;
}
/* Animation for the background gradient movement */
@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Slider section with animated gradient */
.slider_section {
    background: linear-gradient(to right, #1217a2, #2a2856);
    background-size: 200% 200%;
    animation: sliderMove 10s ease infinite;
    padding: 50px 0;
}

@keyframes sliderMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Service section with animated gradient */
.service_section {
    background: linear-gradient(to right, #dfdfdf, #f5e6ff);
    background-size: 200% 200%;
    animation: serviceMove 10s ease infinite;
    padding: 50px 0;
}

@keyframes serviceMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Blog section with animated gradient */
.blog_section {
    background: linear-gradient(to right, #f8ffae, #027627);
    background-size: 200% 200%;
    animation: blogMove 10s ease infinite;
    padding: 50px 0;
}

@keyframes blogMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Contact section with animated gradient */
.contact_section form button {
    background: linear-gradient(to right, #43c6ac, #191654);
    background-size: 200% 200%;
    animation: contactMove 10s ease infinite;
    color: #fff;
    font-weight: bold;
    transition: background 0.3s ease;
}

@keyframes contactMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Common keyframes for animations */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Map container styles */
.map_container {
    margin-top: 20px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
/* Container and layout */
/* Container and layout */
.products {
    padding: 60px 0;
    background-color: #ffffff;
   
  }
  
  .heading_container h2 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px;
    font-weight: 700;
    color: #260e3f;
  }
  
  /* Grid layout for products */
  .product_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
  
  .product_card {
    flex: 1 1 30%; /* Adjusts the width dynamically */
    max-width: 30%;
    display: flex;
    justify-content: center;
    width: 70vh;
  }
  
  .product_box {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
    width: 70vh;
  }
  
  .product_box:hover {
    transform: translateY(-10px);
  }
  
  /* Image box */
  .product_image_box {
    padding: 20px;
    background-color: #f1f1f1;
  }
  .pro{
    margin-left: 65vh;
  }
  .product_image {
    max-width: 40vh;
    height: 50vh;
    display: block;
    margin: 0 auto;
    object-fit: cover;
  }
  
  /* Product title styling */
  .product_detail {
    padding: 15px;
  }
  
  .product_title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 0;
    padding: 10px 0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .product_card {
      max-width: 45%;
      flex: 1 1 45%;
    }
  }
  
  @media (max-width: 576px) {
    .product_card {
      max-width: 100%;
      flex: 1 1 100%;
    }
  }
  .about_section .about_features {
    margin-top: 15px;
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .about_section .about_features li {
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
  }
  
  .about_section .about_link {
    display: inline-block;
    margin-top: 15px;
    color: #fff;
    background-color: #ff6600;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .about_section .about_link:hover {
    background-color: #e65500;
  }
  
  
  .animated_images_section {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #1217a2, #eef2f3);
    margin: 40px 0;
    height: auto; /* Allow height to adjust based on content */
    padding: 20px; /* Add some padding for mobile */
  }
  
  .image_wrapper {
    display: flex; /* Align items horizontally */
    align-items: center; /* Center items vertically */
    gap: 20px; /* Reduced space between items on smaller screens */
    flex-direction: column; /* Stack items vertically on smaller screens */
  }
  
  .animated_tiger, .animated_pipe {
    max-width: 100%; /* Set max width to 100% for responsiveness */
    height: auto; /* Maintain aspect ratio */
    border-radius: 0; /* No border radius needed */
    animation: float 4s ease-in-out infinite; /* Optional floating animation */
  }
  
  .info_text {
    height: 41vh;
    max-width: 90%; 
    color: #ffffff; /* Text color */
    background: linear-gradient(to right, #1217a2, #282738);
    padding: 20px; /* Add padding around the text */
    border-radius: 10px; /* Rounded corners for a softer look */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    transition: transform 0.3s; /* Smooth transition for hover effect */
  }
  
  .info_text h1 {
    font-size: 24px; /* Adjusted font size for mobile */
    margin-bottom: 10px; /* Space below the heading */
    text-transform: uppercase; /* Make heading uppercase for emphasis */
    letter-spacing: 1px; /* Add spacing between letters */
  }
  
  .info_text p {
    font-size: 0.9rem; /* Adjust font size for the paragraph */
    line-height: 1.5; /* Increase line height for readability */
  }
  
  /* Hover effect to make it pop */
  .info_text:hover {
    transform: scale(1.05); /* Slightly increase size on hover */
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Media Queries for Responsiveness */
  @media (min-width: 768px) {
    .image_wrapper {
      flex-direction: row; /* Arrange items in a row for larger screens */
    }
  
    .animated_tiger, .animated_pipe {
      max-width: 900vh; /* Set specific width for larger screens */
      height: 40vh; /* Maintain height for larger screens */
      margin-left: -38vh; /* Optional margin adjustment */
      gap: 10px !important;
    }
  }
  
  @media (max-width: 767px) {
    .info_text {
      text-align: center; /* Center text on smaller screens */
    }
    .animated_tiger{
        height: 30vh;
        width: 900vw !important;
    }
  }
  