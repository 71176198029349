/* DealerInquiry.css */

/* Gradient background with animation */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background: linear-gradient(10deg, #8daff3, #ffffff, #f3f3f3, #140e50);
    background-size: 400% 400%;
    animation: gradientBG 10s ease infinite;
  }
  
  /* Animation for gradient background */
  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .dealer-inquiry {
    background: linear-gradient(to right, #1217a2, #65a9ba);
    background-size: 400% 400%;
    animation: gradientBG 10s ease infinite;
    padding: 50px 0;
    border-radius: 10px;
}

  .form-section {
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .heading-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-heading {
    font-size: 28px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .form-subtitle {
    font-size: 16px;
    color: #666666;
  }
  
  .inquiry-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background-color: #f7f7f7;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .form-group textarea {
    min-height: 120px;
    resize: vertical;
  }
  
  .form-button {
    text-align: center;
  }
  
  .submit-button {
    padding: 10px 30px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  