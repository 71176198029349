
.finolex-bg {
  width: 100%;
  height: 100vh;
  background: linear-gradient(270deg, #023178, #0e1146, #1404a3, #03038e);
  background-size: 600% 600%;
  animation: finolexGradientBG 10s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes finolexGradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.finolex-container {
  max-width: 800px;
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: box-shadow 0.3s ease;
}

.finolex-container:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.finolex-title {
  color: #0d6efd;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.finolex-description {
  color: #333;
  font-size: 1.1em;
  line-height: 1.6em;
  margin-bottom: 20px;
}

.finolex-button-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.finolex-button {
  text-decoration: none;
  padding: 12px 25px;
  color: white;
  background-color: #0d6efd;
  border-radius: 5px;
  font-size: 1em;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.finolex-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
