/* Container for the entire product page */
.product-page {
  padding: 40px 20px;
  text-align: center;
  background-color: #f9f9f9;
}

/* Styling for the "Our Products" heading */
.product-heading {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 50px;
  color: #222;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Container for the product grid */
.product-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  justify-items: center;
  align-items: start;
}

/* Styling each product card */
.product-card {
  position: relative;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  border-radius: 15px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  max-width: 320px; /* Reduced card width slightly */
  height: 360px; /* Reduced card height */
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.product-card:hover {
  transform: scale(1.05);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
}

/* Card image styling */
.product-image {
  width: 100%;
  height: 250px; /* Reduced image height */
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: filter 0.3s ease;
}

.product-card:hover .product-image {
  filter: brightness(85%);
}

/* Product title */
.product-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0;
  color: #333;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s ease;
}

/* Buy Now button */
.product-button {
  display: block;
  width: 85%;
  margin: 10px auto;
  padding: 10px; /* Reduced button padding */
  background-color: #084688;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.product-button:hover {
  background-color: #0056b3;
  transform: translateY(-5px);
}

/* Optional: Add floating effect for the entire card */
.product-card:hover {
  transform: translateY(-8px);
}
