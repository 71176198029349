/* Footer styles */
.footer_section {
    background-color: #fefefe;
    color: #fff;
    padding: 30px 0;
    height: 10vh !important;
  }
  
  .info_section {
    background-color: #000;
    padding: 40px 0;
  }
  
  .info_contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .info_item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 10vh;
  }
  
  .info_item img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .info_item span {
    color: #fff;
    font-size: 16px;
  }
  
  .email-text {
    color: #fff;
    font-size: 16px;
   margin-left: 50vh;
   margin-top: 4vh;
  }
  
  .email-icon {
    color: #fff;
  }
  
  .email-text a {
    color: #fff;
    text-decoration: none;
    margin-left: 5px;
  }
  
  .email-text a:hover {
    text-decoration: underline;
  }
  
  .footer_section a {
    color: #fff;
  }
  
  .footer_section a:hover {
    text-decoration: underline;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .info_item span {
      font-size: 16px;
    }
   .info_item{
    margin-left: 0vh;
  }
    .info_item img {
      width: 25px;
      height: 25px;
    }
  
    .email-text {
      font-size: 25px;
      margin-left:-1vh;
      margin-top: -2vh;
    }
  
    .email-text a {
      font-size: 14px;
    }
  
    .footer_section {
      padding: 20px 0;
    }
  
    .info_section {
      padding: 20px 0;
    }
  
    .info_contact {
      flex-direction: column;
      align-items: center;
    }
  
    .col-md-4 {
      flex: 1 0 100%;
      margin-bottom: 20px;
    }
  }
  