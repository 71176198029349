/* Main Page Styling */
.pvc-pipes-page {
    padding: 20px;
    font-family: 'Roboto', sans-serif; /* Clean, modern font */
    background-color: #f4f4f4; /* Softer background */
    color: #333; /* Default text color */
}

/* Content Container Layout */
.content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px; /* More breathing space between text and images */
    margin-bottom: 40px; /* Spacing at bottom */
}
/* Updated Heading Styling */
h1 {
    font-size: 48px; /* Larger font size for impact */
    font-weight: bold; /* Strong emphasis */
    color: #fffefe !important; /* White text for contrast */
    text-align: center; /* Center the heading */
    background: linear-gradient(90deg, #0b0eb9, #060777); /* Gradient background */
    padding: 20px; /* Add space around the text */
    border-radius: 10px; /* Smooth rounded corners */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
    margin-bottom: 40px; /* Space below the heading */
    transition: transform 0.3s ease-in-out, background 0.3s ease-in-out; /* Smooth transition for hover effects */
    

}

/* Hover effect for the heading */
h1:hover {
    transform: translateY(-5px); /* Slight movement on hover */
    background: linear-gradient(90deg, #140896, #1e0fbc); /* Reverse gradient on hover */
}

/* Text Content Styling */
.text-content {
    flex: 1;
    margin-right: 20px;
}

/* Headings Styling with Stable Underline */
 h2, h3 {
    color: #333; /* Dark color for headings */
    position: relative;
    padding-bottom: 8px; /* Space for underline */
    margin-bottom: 20px; /* Spacing after headings */
    font-weight: bold;
}

/* Underline effect for headings */
 h2::after, h3::after {
    content: '';
    display: block;
    width: 30%; /* Half-width underline for cleaner look */
    height: 3px; /* Thickness of the underline */
    background-color: #0015d1; /* Blue underline */
    position: absolute;
    left: 0;
    bottom: 0;
    transition: width 0.3s ease; /* Smooth transition */
}

/* Accordion Header Styling */
.accordion-header {
    cursor: pointer;
    background-color: #0106a4; /* Primary blue color */
    color: white;
    padding: 15px;
    border: none;
    border-radius: 8px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    font-weight: bold; /* Stronger header text */
}

/* Hover effect for accordion headers */
.accordion-header:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.accordion-icon {
    margin-left: 10px;
    color: white;
}

/* Accordion Content Styling */
.accordion-content {
    padding: 15px;
    border: 1px solid #002fff;
    border-radius: 5px;
    margin-top: 5px;
    background-color: #e7f1ff; /* Light blue background */
    animation: fadeIn 0.5s ease-in-out;
}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Image Container Styling */
.image-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px; /* Space between images */
}

/* Image Styling */
.pvc-image {
    width: 80vh;
    max-width: 400px;
    object-fit: cover;
    height: 70vh;
  margin-right: 10vh;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Stronger shadow for professional look */
    transition: transform 0.3s ease;
}

/* Hover effect for images */
.pvc-image:hover {
    transform: scale(1.05); /* Zoom in effect */
}

/* List Styling */
ul {
    margin-left: 20px; /* Indent list items */
    line-height: 1.6; /* Improve readability */
}

/* Responsive Improvements */
@media (max-width: 768px) {
    .content-container {
        flex-direction: column;
        align-items: center;
    }

    .text-content, .image-container {
        margin-right: 0;
        margin-bottom: 20px; /* Add margin between sections on mobile */
    }

    .image-container {
        align-items: center;
    }

    .pvc-image {
        max-width: 100%;
        margin-left:10vh ;
        height: 45vh; /* Full width on smaller screens */
    }
}
/* Breadcrumb Navigation Styling */
.breadcrumb {
    font-size: 16px; /* Font size for breadcrumb */
    color: #007bff; /* Color for breadcrumb links */
    margin-bottom: 20px; /* Space below breadcrumb */
}

.breadcrumb .separator {
    margin: 0 5px; /* Space around separators */
}

.breadcrumb span:hover {
    text-decoration: underline; /* Underline effect on hover */
    cursor: pointer; /* Pointer cursor for clickable items */
}
.coming-soon-banner {
  background-color: #ffcc00; /* Yellow background */
  color: #333; /* Dark text */
  font-size: 2.4rem;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 5px;
}
